import * as React        from 'react';
import Button            from '@mui/material/Button';
import Dialog            from '@mui/material/Dialog';
import DialogActions     from '@mui/material/DialogActions';
import DialogContent     from '@mui/material/DialogContent';
import DialogTitle               from '@mui/material/DialogTitle';
import { Trans, useTranslation } from 'react-i18next'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function ZoneChangeDialog({ zone, changedZone }) {

  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDiffs = () => {
    if (!zone || !changedZone){
      return []
    }
    const result = []
    for (const zoneKey in zone) {
      if (zone[zoneKey] === changedZone[zoneKey]) {
        continue
      }
      if (Array.isArray(zone[zoneKey])) {
        const normalizedZoneVal = zone[zoneKey].map(it => typeof it === 'object' ? JSON.stringify(it) : it).join(', ')
        const normalizedChangedZoneVal = changedZone[zoneKey].map(it => typeof it === 'object' ? JSON.stringify(it) : it).join(', ')
        if (normalizedZoneVal !== normalizedChangedZoneVal){
          result.push({ key: zoneKey, oldVal: normalizedZoneVal, newVal: normalizedChangedZoneVal })
        }
        continue
      }
      if (typeof zone[zoneKey] === 'boolean') {
        result.push({ key: zoneKey, oldVal: zone[zoneKey].toString(), newVal: changedZone[zoneKey].toString() })
        continue
      }
      if (typeof zone[zoneKey] === 'object') {
        const normalizedZoneVal = JSON.stringify(zone[zoneKey])
        const normalizedChangedZoneVal = JSON.stringify(changedZone[zoneKey])
        if (normalizedZoneVal !== normalizedChangedZoneVal){
          result.push({ key: zoneKey, oldVal: normalizedZoneVal, newVal: normalizedChangedZoneVal })
        }
        continue
      }

      result.push({ key: zoneKey, oldVal: zone[zoneKey], newVal: changedZone[zoneKey] })
    }
    return result.filter(it => ['created','modified'].indexOf(it.key) === -1)
  }

  return (
    <React.Fragment>
      <Button type={'button'} size={'small'} onClick={handleClickOpen}>
        {t('Show changes')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{t('Showing zone changes')}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('ZoneChangeKey')}</TableCell>
                  <TableCell align="right">{t('ZoneChangeOldValue')}</TableCell>
                  <TableCell align="right">{t('ZoneChangeNewValue')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getDiffs().map((row) => {
                  return (
                    <TableRow
                      key={`row-${row.key}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.key}
                      </TableCell>
                      <TableCell align="right">{row.oldVal}</TableCell>
                      <TableCell align="right">{row.newVal}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}